@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");
.grid-container {
  display: grid;
  grid: "sidenav header" 4rem "sidenav body" 1fr / min-content 1fr;
  min-height: 100vh;
}

.sidenav {
  grid-area: sidenav;
}

.header {
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 0;
}

.header-search button {
  padding: 8px 12px;
}

.ais-SearchBox-form {
  position: relative;
}
.ais-SearchBox-form .content-wrap {
  padding: 5px 10px;
  position: relative;
}
.ais-InstantSearch {
  width: 70%;
  margin-left: 10px !important;
}

.ais-SearchBox-input {
  width: 100%;
  height: 38px;
  border: 1px solid #ced4da;
  outline: none;
  padding-left: 5px;
}

.search-content {
  display: block;
  background-color: rgb(255, 255, 255);
  position: absolute;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 9;
  width: 100%;
  height: 252px;
  overflow-y: scroll;
}

.ais-SearchBox-form .content-wrap h3 {
  font-size: 16px;
  margin-bottom: 0;
  transition: all 0.3s ease;
}

.ais-SearchBox-form .cover-link {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  transition: all 0.3s ease;
}

.ais-SearchBox-form .content-wrap:hover h3,
.ais-SearchBox-form .content-wrap:hover span {
  /* background-color: #0257d5 */
  color: #00b1f0;
}
.ais-SearchBox-form .content-wrap span {
  font-size: 14px;
  transition: all 0.3s ease;
}
.wrape-content {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.body {
  grid-area: body;
  display: flex;
  padding: 1rem;
  margin: 0;
  background-color: aliceblue;
}

.drawer {
  position: relative;
  margin: 0;
  width: 12rem;
  height: 100%;
  min-height: 100vh;
  transition: width 0.5s;
  background-color: #5784bb;
  color: white;
}
.drawer.mini {
  width: 4rem;
  transition: 0.5s;
}
.drawer a {
  display: flex;
  align-items: baseline;
  color: inherit;
  padding: 0.5rem 1.375rem;
}
.drawer a.active {
  background-color: #9ac8ea;
  color: #1f2b5c;
  cursor: default;
  text-decoration: none;
}
.drawer a:not(.active):hover {
  color: inherit;
  text-decoration: none;
}
.drawer a i {
  font-size: 1.25rem;
}
.drawer a span {
  font-size: 1rem;
  margin-left: 1rem;
  opacity: 1;
  transition: opacity 0.5s;
}
.drawer a span.mini {
  visibility: hidden;
  opacity: 0;
}

.drawer-button {
  position: absolute;
  top: 3.5rem;
  right: -0.8rem;
  transform: rotate(180deg);
  box-shadow: -1px -2px 4px 1px;
  border: none;
  border-radius: 50%;
  background-color: white;
  color: slategray;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
  transition: 0.5s;
}
.drawer-button.closed {
  transform: rotate(0deg);
  box-shadow: 1px 2px 4px 1px;
}

#drawer-button-icon {
  margin-bottom: 2px;
  color: slategrey;
}

.branding {
  min-height: 4rem;
  width: 100%;
  background-color: #1f2b5c;
  display: flex;
  margin-bottom: 1rem;
}

.brand {
  height: 2.5rem;
  align-self: center;
  margin: -0.25rem 0 0 2.25rem;
  transition: 0.5s;
}
.brand.icon {
  margin: -0.25rem 0 0 1.05rem;
}

.header-toolbar {
  display: flex;
  width: -moz-min-content;
  width: min-content;
  align-items: center;
}

.btn-basic {
  border: none;
  background: transparent;
}

.small-dropdown > li {
  font-size: small;
}

.notfound-wrapper {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard-widget {
  min-height: 350px;
}

.option-btn {
  border: none;
  background-color: #f7f7f7;
  padding: 0;
}

.lgi-flush {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.x-small {
  font-size: 0.6rem;
}

.bg-todo {
  background-color: #dc93a6 !important;
}
.bg-inprogress {
  background-color: #e8d495 !important;
}
.bg-done {
  background-color: #a9c8c0 !important;
}

#profile-tool {
  padding: 0 0.5rem;
}

.profile-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  color: #ffffff;
}

.avatar-img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}

.profile-full {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  color: #ffffff;
  font-size: 2.75rem;
}

#user-profile .user-display-name {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  color: darkslategray;
}
#user-profile .user-phone,
#user-profile .user-email {
  color: darkslategray;
  font-weight: 300;
  font-size: 0.85rem;
}
#user-profile .profile-section-title {
  text-align: left !important;
  margin-top: 3rem;
  width: 100%;
  margin-bottom: 0.5rem;
}
#user-profile .address,
#user-profile .company-phone {
  width: 100%;
}
#user-profile .address p,
#user-profile .company-phone p {
  font-size: 0.85rem;
  color: darkslategray;
  font-weight: 300;
  line-height: 1.2;
  margin: 0;
}
#user-profile .address p.title,
#user-profile .company-phone p.title {
  font-weight: 500;
}

.social-icon-group {
  display: flex;
  justify-content: space-around;
  width: 60%;
  font-size: 1.5rem;
  margin: 1rem 0;
  color: lightgray;
}
.social-icon-group i.bi-linkedin.active {
  color: #0a66c2;
}
.social-icon-group i.bi-microsoft-teams.active {
  color: #4b53bc;
}
.social-icon-group i.bi-skype.active {
  color: #00b1f0;
}
.social-icon-group i.bi-slack.active {
  color: #d6196b;
}

body {
  background-color: #f8f9fa;
}

.container {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #343a40;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.search-bar {
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1.1rem;
}

.user-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid #e3e6f0;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s;
}
.user-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.user-info {
  display: flex;
  align-items: center;
}

.user-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  -o-object-fit: cover;
     object-fit: cover;
}

h5 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #495057;
}

p {
  font-size: 0.9rem;
  color: #6c757d;
  margin-bottom: 0;
}

.btn {
  font-size: 0.85rem;
}

.social-icon-personal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.5rem;
  margin: 1rem 0;
  color: lightgray;
}
.social-icon-personal a {
  margin: 0 10px;
}
.social-icon-personal i.bi-linkedin.active {
  color: #0a66c2;
}
.social-icon-personal i.bi-skype.active {
  color: #00b1f0;
}

.user-card .user-card-body .row {
  align-items: center;
}
.user-card .user-card-body .row .col {
  text-align: center;
}
.user-card .user-card-body .row .vr {
  width: 1px;
  background-color: black;
  margin: 0 15px;
  transform: scaleX(0.1);
}

.user-name {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  margin-top: 0.3rem;
  color: darkslategray;
}

.user-title {
  text-align: center;
  font-size: 1rem;
  margin-top: 0.1rem;
  color: darkslategray;
}

.user-email {
  text-align: center;
  font-size: 1rem;
  margin-top: 0.8rem;
  color: darkslategray;
}

.user-contact-permission {
  text-align: center;
  font-size: 1rem;
  margin-top: 0.3rem;
  color: darkslategray;
}

.company-display-name {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  color: darkslategray;
}

.avatar-overlay {
  position: relative;
  display: inline-block;
  width: 150px;
  height: 150px;
}
.avatar-overlay .overlay-content {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  border-radius: 50%;
}
.avatar-overlay:hover .overlay-content {
  display: flex;
}

.icon-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 50%;
  font-size: 3.5rem;
}

#company-profile .company-display-name {
  font-size: 1.25rem;
  margin-top: 1rem;
  font-weight: 500;
  text-align: center;
  color: darkslategray;
}
#company-profile .company-contact,
#company-profile .company-email {
  color: darkslategray;
  font-weight: 300;
  font-size: 0.85rem;
  line-height: 1.5;
}
#company-profile .company-address {
  color: darkslategray;
  font-weight: 300;
  font-size: 0.85rem;
  line-height: 1.5;
}
#company-profile .company-city-state {
  color: darkslategray;
  font-weight: 300;
  font-size: 0.85rem;
  line-height: 1.5;
}
#company-profile .profile-section-title {
  text-align: left !important;
  margin-top: 3rem;
  width: 100%;
  margin-bottom: 0.5rem;
}
#company-profile .icon-placeholder {
  position: relative;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}
#company-profile .icon-placeholder .bi-buildings {
  font-size: 3.5rem;
}
#company-profile .icon-placeholder .overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  border-radius: 50%;
}
#company-profile .icon-placeholder:hover .overlay {
  display: flex;
}

.social-icons-group {
  display: flex;
  justify-content: space-around;
  width: 60%;
  font-size: 1.5rem;
  margin: 1rem 0;
  color: lightgray;
}
.social-icons-group i.bi-linkedin.active {
  color: #0a66c2;
}
.social-icons-group i.bi-instagram.active {
  color: #d6196b;
}
.social-icons-group i.bi-facebook.active {
  color: #00b1f0;
}
.social-icons-group i.bi-globe.active {
  color: #0a66c2;
}

#company-team .team-section-title {
  text-align: left !important;
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 3rem;
  width: 100%;
  margin-bottom: 0.5rem;
}
#company-team .card.team {
  background-color: whitesmoke;
}

.team-header {
  background-color: #5784bb;
  color: #ffffff;
}

.social-icons {
  display: flex;
  justify-content: space-around;
  width: 60%;
  font-size: 1rem;
  margin-top: -1rem;
  margin-bottom: 1rem;
  color: lightgray;
}
.social-icons i.bi-linkedin.active {
  color: #0a66c2;
}
.social-icons i.bi-instagram.active {
  color: #d6196b;
}
.social-icons i.bi-facebook.active {
  color: #00b1f0;
}

.team-card {
  flex: 0 1 calc(25% - 10px);
  display: flex;
  margin: 5px;
  padding: 5px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.team-card .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.team-card .card-body .avatar {
  margin-bottom: 1rem;
}
.team-card .card-body .member-name {
  margin-top: 0.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  color: darkslategray;
}
.team-card .card-body .member-title {
  margin-top: 0.25rem;
  font-weight: 500;
  font-size: 0.85rem;
  text-align: center;
  color: darkslategray;
}

#notification-tool > svg {
  font-size: 1.5rem;
  color: #5784bb;
}
#notification-tool > .badge {
  position: absolute;
  top: 0.3rem;
  left: 0.1rem;
}
#notification-tool > .badge.single {
  padding: 0.3em 0.6em;
}
#notification-tool > .badge.double {
  padding: 0.35em;
}
#notification-tool .notification-text {
  font-size: 0.9em;
  font-weight: 300;
}

.header-search {
  max-width: 20rem;
  padding: 0 0.5rem;
}

.search-icon {
  color: white;
}

#login-box {
  width: 30rem;
}

.card-logo {
  margin: 1rem 0;
  height: 3.75rem;
}

.news-title {
  font-size: 0.9rem;
  font-weight: 500;
}

p.news-date-time {
  font-size: 0.6em;
  line-height: 1em;
  margin-bottom: 0;
  font-style: italic;
  padding-top: 5px;
}

span.small,
p.small {
  font-size: 0.75em;
  line-height: 1em;
  margin-bottom: 0;
}

.total-title {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 500;
  color: #40393e;
}

.type-title {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.total-body {
  font-family: "Roboto", sans-serif;
  font-size: 3.5rem;
  margin-bottom: 0;
  margin-top: -15px;
  color: #9ac8ea;
}

.equal-height {
  flex: 1;
}

.card-header.company-header {
  background-color: aliceblue;
}

.company-profile {
  border-radius: 50%;
  width: 3.75rem;
  border: 1px solid darkgrey;
  cursor: pointer;
  background-color: white;
  min-height: 3.75rem;
  min-width: 3.75rem;
  display: flex;
  color: #5784bb;
}

.company-name {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0;
}

span.small,
p.small {
  font-size: 0.75rem;
  line-height: 1;
  margin-bottom: 0;
}

.client-options {
  font-size: 1.25rem;
  padding-top: 0;
  padding-bottom: 0;
}

.list-project-title {
  font-size: 0.9em;
  font-weight: 500;
  line-height: 1;
  padding-top: 0.375rem;
  margin-left: 0.3125rem;
  cursor: pointer;
}

#project-detail .project-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: 5px;
}
#project-detail .project-description {
  color: darkslategray;
  font-weight: 300;
  font-size: 0.85rem;
}
#project-detail .project-status {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
#project-detail .project-status i {
  color: gray;
  margin-left: 1rem;
  cursor: pointer;
}
#project-detail .project-icon-group {
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
  font-size: 1.5rem;
}
#project-detail .project-icon-group .btn-basic {
  padding: 0;
}
#project-detail .project-icon-group i {
  color: lightgray;
}
#project-detail .project-icon-group i:hover {
  color: darkslategray;
  cursor: pointer;
}
#project-detail .project-icon-group i.active {
  color: #5784bb;
}
#project-detail .project-item {
  font-size: 0.85rem;
}
#project-detail .notes {
  color: darkslategray;
  font-weight: 300;
  font-size: 0.85rem;
  padding: 0 1rem;
}
#project-detail .notes span {
  font-weight: 400;
  font-style: italic;
}
#project-detail .card-header {
  background-color: #fff;
  border: none;
}
#project-detail .icon-title {
  font-size: 1.5rem;
}

#project-tasks .option-btn {
  background-color: #fff;
}
#project-tasks .card-body {
  padding: 0px var(--bs-card-spacer-x);
}
#project-tasks .completedTask {
  font-size: 1rem;
  opacity: 0.8;
  font-weight: 500;
  margin-bottom: 1rem;
}
#project-tasks .incompleteTask {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
#project-tasks span {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.list-checkrow input[type="checkbox"]:checked + label {
  opacity: 0.8;
  text-decoration: line-through;
  justify-content: flex-start;
}
.list-checkrow label {
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 320px;
  margin-left: 5px;
}

.inventory-item {
  font-size: 0.85rem;
}

.options li {
  font-size: small;
}

.contact {
  border-radius: 50%;
  width: 3rem;
  cursor: pointer;
  background-color: #5784bb;
  min-height: 48px;
  min-width: 48px;
  display: flex;
  color: #ffffff;
}

.list-contact-name {
  font-size: 0.9rem;
  margin-bottom: 0;
  line-height: 1.2;
  font-weight: 600;
  cursor: pointer;
}

.list-contact-phone {
  font-size: small;
  margin-bottom: 0;
  line-height: 1.2;
}
.list-contact-phone span {
  font-size: 0.9rem;
}

.list-contact-email {
  font-size: small;
  margin-bottom: 0;
  line-height: 1.2;
}

.taskItem {
  height: 30px;
  margin-bottom: 7px;
}
.taskItem input[type="checkbox"]:checked + label {
  opacity: 0.8;
  text-decoration: line-through;
  justify-content: flex-start;
}
.taskItem label {
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 320px;
  margin-left: 5px;
}

table {
  width: 100%;
  table-layout: fixed;
  overflow-wrap: break-word;
}

.title,
.stage {
  font-weight: 500;
}

.stage {
  min-width: 30px;
  max-width: 30px;
  overflow: hidden;
  color: white;
  font-weight: 600;
}

table,
th,
td {
  border: 3px white solid;
}

td.actions {
  text-align: center;
}

span.action-item {
  margin-left: 5px;
  margin-right: 5px;
}

#tasks-status-details .option-btn {
  background-color: #fff;
}
#tasks-status-details .card-body {
  padding: 0px var(--bs-card-spacer-x);
}
#tasks-status-details .card-header {
  background-color: #fff;
  border: none;
}
#tasks-status-details .task-heading {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
#tasks-status-details .task-completed {
  font-size: 1rem;
  margin-bottom: 1rem;
  opacity: 0.8;
}
#tasks-status-details span {
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

#tasks-details-card .card-body {
  padding: 0px var(--bs-card-spacer-x);
}
#tasks-details-card .icon-title {
  margin: 0;
  line-height: 1.75;
}
#tasks-details-card .task-content-title {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.3rem;
}
#tasks-details-card .attachment-title {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.3rem;
}
#tasks-details-card .attachment-details {
  margin: 0;
  line-height: 1.75;
}
#tasks-details-card .icon-common-task {
  font-size: 1.25rem;
}
#tasks-details-card .icon-view-attachment {
  font-size: 1.25rem;
  color: goldenrod;
}
#tasks-details-card .task-detail-title {
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
#tasks-details-card .task-edit-save {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #fff;
}
#tasks-details-card .task-edit-cancel {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#tasks-details-card .form-select {
  font-size: 1rem;
}

#goal-detail .goal-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
#goal-detail .goal-description {
  color: darkslategray;
  font-weight: 300;
  font-size: 0.85rem;
}
#goal-detail .goal-status {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
#goal-detail .goal-status i {
  color: gray;
  margin-left: 1rem;
  cursor: pointer;
}
#goal-detail .goal-icon-group {
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
  font-size: 1.5rem;
}
#goal-detail .goal-icon-group .btn-basic {
  padding: 0;
}
#goal-detail .goal-icon-group i {
  color: lightgray;
}
#goal-detail .goal-icon-group i:hover {
  color: darkslategray;
  cursor: pointer;
}
#goal-detail .goal-icon-group i.active {
  color: #5784bb;
}
#goal-detail .inventory-item {
  font-size: 0.85rem;
}
#goal-detail .notes {
  color: darkslategray;
  font-weight: 300;
  font-size: 0.85rem;
  padding: 0 1rem;
}
#goal-detail .notes span {
  font-weight: 400;
  font-style: italic;
}

#initiative-select {
  width: 80%;
}

.hierarchy-list-item i {
  font-size: 0.75rem;
  line-height: 1.35;
}
.hierarchy-list-item p {
  line-height: 1.2;
  margin-bottom: 0.35rem;
}

ul {
  list-style: none;
  padding-left: 20px;
  margin-bottom: 0;
}
ul li {
  position: relative;
}
ul li:before {
  position: absolute;
  left: -15px;
  top: 0px;
  content: "";
  display: block;
  border-left: 1px solid #ddd;
  height: 0.5em;
  border-bottom: 1px solid #ddd;
  width: 10px;
}
ul li:after {
  position: absolute;
  left: -15px;
  bottom: -7px;
  content: "";
  display: block;
  border-left: 1px solid #ddd;
  height: 100%;
}
ul li.root {
  margin: 0px 0px 0px -20px;
}
ul li.root:before {
  display: none;
}
ul li.root:after {
  display: none;
}
ul li:last-child:after {
  display: none;
}

#initiative-detail .initiative-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
#initiative-detail .initiative-description {
  color: darkslategray;
  font-weight: 300;
  font-size: 0.85rem;
}
#initiative-detail .initiative-status {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
#initiative-detail .initiative-status i {
  color: gray;
  margin-left: 1rem;
  cursor: pointer;
}
#initiative-detail .initiative-icon-group {
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
  font-size: 1.5rem;
}
#initiative-detail .initiative-icon-group .btn-basic {
  padding: 0;
}
#initiative-detail .initiative-icon-group i {
  color: lightgray;
}
#initiative-detail .initiative-icon-group i:hover {
  color: darkslategray;
  cursor: pointer;
}
#initiative-detail .initiative-icon-group i.active {
  color: #5784bb;
}
#initiative-detail .inventory-item {
  font-size: 0.85rem;
}
#initiative-detail .notes {
  color: darkslategray;
  font-weight: 300;
  font-size: 0.85rem;
  padding: 0 1rem;
}
#initiative-detail .notes span {
  font-weight: 400;
  font-style: italic;
}
#initiative-detail .card-header {
  background-color: #fff;
  border: none;
}
#initiative-detail .icon-title {
  font-size: 1.5rem;
}

#initiative-tasks .option-btn {
  background-color: #fff;
}
#initiative-tasks .card-body {
  padding: 0px var(--bs-card-spacer-x);
}

.progress {
  background-color: #f8f9fa;
}

.progress-bar {
  background: linear-gradient(to right, #c7e6f2, #2061ab);
  transition: width 0.5s ease-in-out;
  border-radius: 3px;
}

.progress-caret {
  position: absolute;
  transform: translateX(-50%);
  color: #276dc2;
  font-size: 15px;
  margin-top: 10px;
}

.progress-container {
  position: relative;
  height: 65px;
}

.progress-labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 8px;
  font-weight: bold;
  color: #276dc2;
}

.progress-title {
  font-size: 0.75rem;
}

.carousel-container {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 7px;
}

.carousel-control-prev,
.carousel-control-next {
  width: 10px;
  height: 5px;
  display: flex;
  position: relative;
}

.carousel-control-prev-icon::after {
  content: "‹";
  font-size: 1.5rem;
  color: black;
}

.carousel-control-next-icon::after {
  content: "›";
  font-size: 1.5rem;
  color: black;
}

.bi-info-circle {
  color: #276dc2;
  margin-left: 3px;
}

.impacts-list-item i {
  font-size: 0.85rem;
}

.question-icon {
  color: #ffffff;
  background-color: #0d6efd;
  border-radius: 50%;
  padding: 0.2rem;
  font-size: 0.8rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  text-align: center;
}

.data-point .modal-title {
  font-size: 1.5rem;
  opacity: 0.5;
}
.data-point .d-flex {
  align-items: center;
}
.data-point .d-flex .form-label {
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 0;
  opacity: 0.5;
}
.data-point .d-flex span {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  opacity: 0.7;
}
.data-point .d-flex .form-select {
  font-size: 0.8rem;
  width: 150px;
  height: 30px;
}

.modal-body .d-flex.justify-content-end.mt-4 .btn {
  padding: 5px 60px;
  font-size: 16px;
}

.modal-body .d-flex.justify-content-end.mt-4 .btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.modal-body .d-flex.justify-content-end.mt-4 .btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.modal-body .d-flex.justify-content-end.mt-4 .btn-primary {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.modal-body .d-flex.justify-content-end.mt-4 .btn-primary:hover {
  background-color: #0257d5;
  border-color: #0257d5;
}

.custom-impact-table {
  width: 100%;
  border-collapse: collapse;
}
.custom-impact-table th,
.custom-impact-table td {
  padding: 10px;
  border: 1px solid #ffffff;
}
.custom-impact-table thead th:nth-child(odd) {
  background-color: #e9ecef;
}
.custom-impact-table thead th:nth-child(even) {
  background-color: #f8f9fa;
}
.custom-impact-table tbody td:nth-child(odd) {
  background-color: #e9ecef;
}
.custom-impact-table tbody td:nth-child(even) {
  background-color: #f8f9fa;
}
.custom-impact-table tbody tr:hover {
  background-color: #f1f1f1;
}
.custom-impact-table tbody tr.red-highlight td {
  background-color: #f8d7da !important;
}

#impact-detail .impact-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
#impact-detail .impact-description {
  color: darkslategray;
  font-weight: 300;
  font-size: 0.85rem;
}
#impact-detail .impact-status {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
#impact-detail .impact-status i {
  color: gray;
  margin-left: 1rem;
  cursor: pointer;
}
#impact-detail .impact-icon-group {
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
  font-size: 1.5rem;
}
#impact-detail .impact-icon-group .btn-basic {
  padding: 0;
}
#impact-detail .impact-icon-group i {
  color: lightgray;
}
#impact-detail .impact-icon-group i:hover {
  color: darkslategray;
  cursor: pointer;
}
#impact-detail .impact-icon-group i.active {
  color: #5784bb;
}
#impact-detail .inventory-item {
  font-size: 0.85rem;
}
#impact-detail .notes {
  color: darkslategray;
  font-weight: 300;
  font-size: 0.85rem;
  padding: 0 1rem;
}
#impact-detail .notes span {
  font-weight: 400;
  font-style: italic;
}
#impact-detail .card-header {
  background-color: #fff;
  border: none;
}
#impact-detail .icon-title {
  font-size: 1.5rem;
}

#client-detail .client-name {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: 5px;
}
#client-detail .client-address {
  font-size: 0.85rem;
  font-weight: 300;
}
#client-detail .edit-client {
  font-size: 0.85rem;
  font-weight: 400;
}
#client-detail .asterisk-form {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  font-size: 1.2em;
  margin-left: 5px;
  color: #c44b6c;
}
#client-detail .client-icon-group {
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
  font-size: 1.5rem;
}
#client-detail .client-icon-group .btn-basic {
  padding: 0;
}
#client-detail .client-icon-group i {
  color: lightgray;
}
#client-detail .client-icon-group i:hover {
  color: darkslategray;
  cursor: pointer;
}
#client-detail .client-icon-group i.active {
  color: #5784bb;
}
#client-detail .profile-pic-container {
  position: relative;
  width: 6rem;
  height: 6rem;
  overflow: hidden;
}
#client-detail .client-profile-pic {
  -o-object-fit: cover;
     object-fit: cover;
}
#client-detail .profile-pic-hover-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  color: #ffffff;
  opacity: 0;
}
#client-detail .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(198, 195, 195, 0.5);
  opacity: 0;
}
#client-detail .default-profile {
  width: 6rem;
  height: 6rem;
  position: relative;
  overflow: hidden;
  font-size: 3.5rem;
  color: #0b0b0b;
  background-color: #dfdcdc;
}
#client-detail .default-profile:hover .profile-pic-hover-icon,
#client-detail .default-profile:hover .overlay {
  opacity: 1;
}

.card-container {
  display: flex;
}

.client-brief-card {
  height: 230px;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.client-brief-clock {
  font-size: 2.5rem;
  color: #5784bb;
  margin-top: 1rem;
}

.time-rem-month {
  font-size: 1.5rem;
  opacity: 0.8;
}

.time-rem-month-message {
  font-size: 0.8rem;
  opacity: 0.8;
}

.end-date {
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 1px;
}

.budget-message {
  font-size: 0.8rem;
  opacity: 0.8;
  margin-top: 2px;
}

.budget-total {
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 1px;
}

.statement {
  font-weight: bold;
  color: #5784bb;
}

.contract-statement {
  white-space: normal;
  text-overflow: clip;
  word-wrap: break-word;
}

.client-goal {
  color: #5784bb;
}

.goal-title {
  overflow: hidden;
  white-space: nowrap;
}

.budget-message-available {
  font-size: 1rem;
  opacity: 0.8;
  margin-bottom: 1rem;
}

.client-goal-timeline {
  font-size: 1.2rem;
  opacity: 0.8;
}

.caret-icon {
  color: #5784bb;
}

#attachment-list .attachment-title {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.3rem;
}
#attachment-list .attachment-name {
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#attachment-list .attachment-input-file-upload {
  display: none;
}
#attachment-list .attachment-action {
  cursor: pointer;
}

.notes {
  color: darkslategray;
  font-weight: 300;
  font-size: 0.85rem;
  padding: 0 1rem;
}
.notes span {
  font-weight: 400;
  font-style: italic;
}

.custom-table {
  border: 1px #ccc solid;
  border-collapse: separate;
  border-spacing: 0 0px;
  width: 100%;
}
.custom-table th,
.custom-table td {
  border: 0px white solid;
  border-bottom: 1px solid #ccc;
  padding: 0.5rem 1rem;
}
.custom-table thead th {
  border-bottom: 1px solid #ccc;
  background-color: #fdfdfd;
  padding: 0.5rem 1rem;
}
.custom-table tbody tr:hover {
  background-color: #f9f9f9;
}

.toast {
  background-color: #f5f5f5;
}

.toast-body {
  text-align: left;
}

.custom-toast-header .error-text {
  order: 1;
}

.custom-toast-header button.close {
  order: 2;
  margin-left: auto;
}

.text-divider {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.text-divider::after {
  content: "";
  height: 1px;
  background-color: silver;
  flex-grow: 1;
}

.recommended {
  font-size: 0.8em;
  display: inline-block;
  font-style: italic;
}

.contract-statement::-moz-placeholder {
  font-style: italic;
}

.contract-statement::placeholder {
  font-style: italic;
}

.custom-link {
  color: inherit;
  text-decoration: none;
}
.custom-link:hover,
.custom-link:focus {
  color: inherit;
  text-decoration: none;
}

.step-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
  top: -30px;
}
.step-indicator .step-circle {
  width: 40px;
  height: 40px;
  background-color: #ddd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}
.step-indicator .step-circle.active {
  background-color: #28a745;
  color: #fff;
}
.step-indicator .step-line {
  position: absolute;
  width: calc(100% - 40px);
  height: 4px;
  background-color: #ddd;
  top: 18px;
  left: 30px;
  z-index: -1;
}
.step-indicator .step-circle + .step-circle::before {
  content: "";
  position: absolute;
  width: calc(33.3333333333% - 40px);
  height: 4px;
  background-color: #ddd;
  top: 18px;
  left: -50%;
}
.step-indicator .step-circle.active + .step-circle::before {
  background-color: #28a745;
}

.accordion-header {
  border-bottom: 1px;
}

.accordion-body {
  padding: 0;
}

.modal-header .modal-title {
  font-size: 1.5rem;
  opacity: 0.5;
}

.timeline-container {
  position: relative;
  height: 100px;
  width: 100%;
  margin: 20px 0;
  padding: 10px 0;
}
.timeline-container .timeline-line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: grey;
  border-radius: 2px;
}
.timeline-container .timeline-end-rule {
  position: absolute;
  top: 50%;
  height: 20px;
  width: 2px;
  background-color: grey;
  transform: translateY(-50%);
}
.timeline-container .timeline-start,
.timeline-container .timeline-end,
.timeline-container .timeline-current,
.timeline-container .timeline-checkpoint {
  position: absolute;
  top: calc(50% - 35px);
  text-align: center;
  transform: translateX(-50%);
}
.timeline-container .timeline-start i,
.timeline-container .timeline-end i,
.timeline-container .timeline-current i,
.timeline-container .timeline-checkpoint i {
  font-size: 26px;
  color: #5784bb;
  cursor: pointer;
}
.timeline-container .timeline-start .timeline-signpost,
.timeline-container .timeline-end .timeline-signpost,
.timeline-container .timeline-current .timeline-signpost,
.timeline-container .timeline-checkpoint .timeline-signpost {
  color: #5784bb;
  opacity: 0.6;
}
.timeline-container .timeline-start .grey-signpost,
.timeline-container .timeline-end .grey-signpost,
.timeline-container .timeline-current .grey-signpost,
.timeline-container .timeline-checkpoint .grey-signpost {
  color: #6c757d;
  opacity: 0.6;
}
.timeline-container .timeline-start .timeline-label,
.timeline-container .timeline-end .timeline-label,
.timeline-container .timeline-current .timeline-label,
.timeline-container .timeline-checkpoint .timeline-label {
  margin-top: 8px;
  font-size: 12px;
  color: #5784bb;
  white-space: pre-wrap;
  text-align: center;
  max-width: 60px;
  word-wrap: break-word;
}
.timeline-container .timeline-start .timeline-caret-below,
.timeline-container .timeline-end .timeline-caret-below,
.timeline-container .timeline-current .timeline-caret-below,
.timeline-container .timeline-checkpoint .timeline-caret-below {
  position: absolute;
  top: 60px;
  left: calc(50% - 12px);
  transform: translateY(-50%);
  font-size: 30px;
  font-weight: bold;
  color: #3F6797;
}
.timeline-container .timeline-start .timeline-flag,
.timeline-container .timeline-end .timeline-flag,
.timeline-container .timeline-current .timeline-flag,
.timeline-container .timeline-checkpoint .timeline-flag {
  margin-left: 16px;
}
.timeline-container .timeline-start .timeline-end-flag,
.timeline-container .timeline-end .timeline-end-flag,
.timeline-container .timeline-current .timeline-end-flag,
.timeline-container .timeline-checkpoint .timeline-end-flag {
  margin-left: 16px;
  color: black;
}
.timeline-container .timeline-start .after-pop-label,
.timeline-container .timeline-end .after-pop-label,
.timeline-container .timeline-current .after-pop-label,
.timeline-container .timeline-checkpoint .after-pop-label {
  color: red;
  font-size: 0.8em;
  margin-top: 4px;
}
.timeline-container .timeline-start .timeline-dot,
.timeline-container .timeline-end .timeline-dot,
.timeline-container .timeline-current .timeline-dot,
.timeline-container .timeline-checkpoint .timeline-dot {
  width: 8px;
  height: 8px;
  margin-right: 12px;
  background-color: #5784bb;
  border-radius: 50%;
  position: absolute;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
}
.timeline-container .timeline-start .timeline-tooltip,
.timeline-container .timeline-end .timeline-tooltip,
.timeline-container .timeline-current .timeline-tooltip,
.timeline-container .timeline-checkpoint .timeline-tooltip {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 6px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  font-size: 12px;
  display: none;
  z-index: 1;
}
.timeline-container .timeline-start .timeline-tooltip .timeline-date,
.timeline-container .timeline-end .timeline-tooltip .timeline-date,
.timeline-container .timeline-current .timeline-tooltip .timeline-date,
.timeline-container .timeline-checkpoint .timeline-tooltip .timeline-date {
  display: block;
  font-weight: bold;
  margin-bottom: 4px;
}
.timeline-container .timeline-start .timeline-tooltip .timeline-title,
.timeline-container .timeline-end .timeline-tooltip .timeline-title,
.timeline-container .timeline-current .timeline-tooltip .timeline-title,
.timeline-container .timeline-checkpoint .timeline-tooltip .timeline-title {
  color: #555;
}
.timeline-container .timeline-start:hover .timeline-tooltip,
.timeline-container .timeline-end:hover .timeline-tooltip,
.timeline-container .timeline-current:hover .timeline-tooltip,
.timeline-container .timeline-checkpoint:hover .timeline-tooltip {
  display: block;
}

#login {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
} /*# sourceMappingURL=app.css.map */
