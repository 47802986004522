.focus-score-card {
  width: 200px;
  height: 229px;
  background: #5683ba; /* Light blue background */
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  margin: 0.5rem !important;
}

.focus-score-title {
  font-size: 1.3rem;
  font-weight: lighter;
  color: #fff;
  margin-bottom: 12px;
  text-transform: uppercase;
}

.focus-score-circle {
  width: 100px;
  height: 100px;
  background: #2c3e6f; /* Dark blue background for circle */
  border-radius: 50%;
  color: #fff;
  font-size: 2.5rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 15px; */
}

.focus-score-value {
  font-size: 2.5rem;
  font-weight: bold;
}

.focus-score-status {
  font-size: 1rem;
  color: #fff;
  font-weight: lighter;
}
.full-container {
  width: 100%;
}
.card-conatiner {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.card-conatiner .col:nth-child(1) {
  max-width: 20%;
}

.card-conatiner .col:nth-child(2) {
  max-width: 20%;
}

.card-conatiner .col:nth-child(3) {
  max-width: 35%;
  width: 100%;
}
.focus-score-card {
  max-width: 20%;
  width: 100%;
}

.focus-score-card svg {
  display: block;
  margin: 0px 17px 0 auto;
  position: relative;
  top: 20px;
}
.focus-score-circle {
  margin: 0px auto 10px auto;
}
